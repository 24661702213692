import i18n from '@/i18n';
import {BvTableField} from 'bootstrap-vue';

const columns: Array<{ key: string } & BvTableField> = [
  {
    label: i18n.t('colunas.id') as string,
    key: 'id',
    sortable: true,
    thStyle: { width: '50px' },
    stickyColumn: true,
  },
  {
    label: i18n.tc('colunas.codigo.usuario', 1) as string,
    key: 'id_usuario',
    sortable: true,
    thStyle: { width: '50px' },
  },
  {
    label: i18n.tc('colunas.nome.usuario', 1) as string,
    key: 'usuarios.nome',
    sortable: true,
  },
  {
    label: i18n.tc('colunas.documento', 1) as string,
    key: 'usuarios.cpf_cnpj',
    sortable: true,
  },
  {
    label: i18n.tc('colunas.contato.telefone', 1) as string,
    key: 'usuarios.telefone',
    sortable: true,
  },
  {
    label: i18n.tc('colunas.contato.email', 1) as string,
    key: 'usuarios.email',
    sortable: true,
  },
  {
    label: i18n.t('colunas.cupom') as string,
    key: 'cupom',
  },
  {
    label: i18n.t('colunas.valor.fatura') as string,
    key: 'valor',
    sortable: true,
    thStyle: { width: '120px' },
    thClass: 'text-center',
  },
  {
    label: i18n.t('colunas.plano') as string,
    key: 'plano',
    sortable: true,
    thStyle: { width: '90px' },
  },
  {
    label: i18n.t('colunas.produtos') as string,
    key: 'produtos',
    sortable: true,
    thStyle: { width: '90px' },
  },
  {
    label: i18n.t('colunas.situacao') as string,
    key: 'situacao',
    sortable: true,
    thStyle: { width: '90px' },
  },
  {
    label: i18n.tc('colunas.data.criacao', 2) as string,
    key: 'data_criacao',
    sortable: true,
    thStyle: { width: '100px' },
    thClass: 'text-center',
  },
  {
    label: i18n.tc('colunas.data.expiracao', 2) as string,
    key: 'data_expiracao',
    sortable: true,
    thStyle: { width: '100px' },
    thClass: 'text-center',
  },
  {
    label: i18n.tc('colunas.data.vencimento', 2) as string,
    key: 'data_vencimento',
    sortable: true,
    thStyle: { width: '100px' },
    thClass: 'text-center',
  },
  {
    label: i18n.tc('colunas.data.pagamento', 2) as string,
    key: 'data_pagamento',
    sortable: true,
    thStyle: { width: '100px' },
    thClass: 'text-center',
  },
];

const situacoes: ISelectSituacao[] = [
  {
    text: i18n.t('faturas.situacoes.todos') as string,
    value: '',
    variant: '',
  },
  {
    text: i18n.t('faturas.situacoes.pendente') as string,
    value: 'pending',
    variant: 'secondary',
  },
  {
    text: i18n.t('faturas.situacoes.pago') as string,
    value: 'paid',
    variant: 'success',
  },
  {
    text: i18n.t('faturas.situacoes.cancelado') as string,
    value: 'canceled',
    variant: 'warning',
  },
  {
    text: i18n.t('faturas.situacoes.vencido') as string,
    value: 'expired',
    variant: 'danger',
  },
];

const columnsFilter: IFiltroField[] = [
  {
    label: i18n.t('colunas.id') as string,
    id: 'crm_assinaturas.id',
    type: 'id',
  },
  {
    label: i18n.tc('colunas.nome.fatura', 2) as string,
    id: 'usuarios.nome',
    type: 'text',
  },
  {
    label: i18n.t('colunas.valor.fatura') as string,
    id: 'faturas.valor',
    type: 'number',
  },
  {
    label: i18n.t('colunas.tipo') as string,
    id: 'crm_assinaturas.tipo',
    type: 'enum',
    enum: [
      { value: 'boleto1', label: i18n.t('faturas.metodo.entrada') as string },
      { value: 'boleto2', label: i18n.t('faturas.metodo.parcela') as string },
      { value: 'cartao', label: i18n.t('faturas.metodo.cartao') as string },
    ],
  },
  {
    label: i18n.t('colunas.plano') as string,
    id: 'crm_assinaturas.plano',
    type: 'enum',
    enum: [
      { value: 'clube_somar_prata', label: i18n.t('faturas.plano.clube_somar_prata') as string },
      { value: 'vendedor_apvs', label: i18n.t('faturas.plano.vendedor_apvs') as string },
    ],
  },
  {
    label: i18n.t('colunas.situacao') as string,
    id: 'faturas.situacao',
    type: 'enum',
    enum: situacoes.map<IFiltroEnum>((item) => {
      return { value: item.value, label: item.text };
    }),
  },
  {
    label: i18n.tc('colunas.data.criacao', 2) as string,
    id: 'faturas.data_criacao',
    type: 'date',
  },
  {
    label: i18n.tc('colunas.data.expiracao', 2) as string,
    id: 'crm_assinaturas.data_expiracao',
    type: 'date',
  },
  {
    label: i18n.tc('colunas.data.vencimento', 2) as string,
    id: 'faturas.data_vencimento',
    type: 'date',
  },
  {
    label: i18n.tc('colunas.data.pagamento', 2) as string,
    id: 'faturas.data_pagamento',
    type: 'date',
  },
];

export { columns, situacoes, columnsFilter };

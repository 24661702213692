


















import { Component, Vue } from 'vue-property-decorator';
import { Faturas } from '@/plugins/api';
import FileSaver from 'file-saver';
import contentDisposition from 'content-disposition';

@Component
export default class FaturasExportar extends Vue {
  get filtros(): IFiltroValues[] {
    return this.$store.getters.filtrosSelecionados;
  }

  public exportarCSV(): void {
    debugger;
    const request = {
      params: {
        format: 'csv',
        filters: this.filtros,
      },
    };
    Faturas.exportar(request)
      .then((response) => {
        const type = response.headers['content-type'];
        const filename = this.getFilename(
          response.headers['content-disposition']
        );
        const blob = new Blob([response.data], { type: type });
        FileSaver.saveAs(blob, filename);

        this.$swal({
          icon: 'success',
          title: 'Baixando Relatório',
        });
      })
      .catch(() => {
        this.$swal({
          icon: 'error',
          title: 'Erro',
        });
      });
  }

  public getFilename(disposition: string): string {
    const disp = contentDisposition.parse(disposition);
    return disp.parameters.filename;
  }
}
